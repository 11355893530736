import React from 'react'

const TagsAndDate = ({tags, date}) => {
    const displayTags = tags?.join(', ')
    const displayDate = new Date(date).toLocaleDateString("en-GB", { day: 'numeric', month: 'long', year: 'numeric', })
    const tagsAndDate = displayTags ? <span>{displayTags}&nbsp;&nbsp;•&nbsp;&nbsp;{displayDate}</span> : displayDate
    return (
        <div className="mb-2 text-sm text-[#9C9C9C]">
            {tagsAndDate}
        </div>
    )
}

export default TagsAndDate