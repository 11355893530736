import React from "react";
import Placeholder from "./Placeholder";
import SimpleCentered from "./SimpleCentered";
import LogoClouds from './LogoClouds'
import FullWidthImage from './FullWidthImage'
import GridWithOffsetIcons from './GridWithOffsetIcons'
import Audio from './Audio'
import WithLargeImages from './WithLargeImages'
import ThreeColumnCardsStatic from './ThreeColumnCardsStatic'
import FourByTwoGridOnBrand from './FourByTwoGridOnBrand'
import SplitWithImageContact from './SplitWithImageContact'
import SimpleFourColumn from './SimpleFourColumn'
import FourByTwoGridOnBrandV2 from './FourByTwoGridOnBrandV2'
import ContentCentered from './ContentCentered'
import Content from './Content'
import ThreeColumnCards from './ThreeColumnCards'

/* Marketing / PageSections / ContentSections */
import Centered from "./Marketing/PageSections/ContentSections/Centered";
import SimpleThreeColumn from "./SimpleThreeColumn";
import SplitWithImage from "./Marketing/PageSections/ContentSections/SplitWithImage";
import TwoColumns from "./Marketing/PageSections/ContentSections/TwoColumns";
import TwoColumnsWithImage from "./Marketing/PageSections/ContentSections/TwoColumnsWithImage";
import TwoColumnsWithTestimonial from "./Marketing/PageSections/ContentSections/TwoColumnsWithTestimonial";
import WithTestimonialAndStats from "./Marketing/PageSections/ContentSections/WithTestimonialAndStats";
import Stat from "./Marketing/PageSections/ContentSections/Stat";
import ThreeColumnImage from"./Marketing/PageSections/ContentSections/ThreeColumnImage"

/* Marketing / PageSections / Contact Sections */
import ContactCentered from "./Marketing/PageSections/ContactSections/Centered";

/* Marketing / PageSections / CTA Sections */
import BrandPanelOverlappingImage from "./Marketing/PageSections/CTASections/brand-panel-with-overlapping-image";
import BrandPanelAppScreenshot from "./Marketing/PageSections/CTASections/brand-panel-with-app-screenshot";
import SimpleJustified from "./Marketing/PageSections/CTASections/simple-justified";
import SimpleCenteredBranded from "./Marketing/PageSections/CTASections/simple-centered-branded"

/* Marketing / PageSections / FAQs */
import OffsetSupportingText from "./Marketing/PageSections/FAQs/offset-supporting-text";

/* Marketing / PageSections / Testimonials */
import TestimonialsSimpleCentered from "./Marketing/PageSections/Testimonials/SimpleCentered";

/* Marketing / Feedback / 404 Pages */
import Simple404 from "./Marketing/Feedback/404Pages/simple";

/* TBB */
import CenteredAccordion from "./TBB/Accordions/centered-accordion";
import Hero from "./TBB/Heros/Hero";

/* Custom */
import GoogleMap from "./Custom/GoogleMap";
import Video from "./Custom/Video";

const Components = {
  "hero": Hero,
  "simple-centered": SimpleCentered,
  "logo-clouds": LogoClouds,
  "full-width-image": FullWidthImage,
  "grid-with-offset-icons": GridWithOffsetIcons,
  "audio": Audio,
  "with-large-images": WithLargeImages,
  "three-column-cards-static": ThreeColumnCardsStatic,
  "four-by-two-grid-on-brand": FourByTwoGridOnBrand,
  "split-with-image-contact": SplitWithImageContact,
  "simple-four-column": SimpleFourColumn,
  "four-by-two-grid-on-brand-v2": FourByTwoGridOnBrandV2,
  "content-centered": ContentCentered,
  "content": Content,
  "three-column-cards": ThreeColumnCards,
  /* Marketing / PageSections / ContentSections */
  "split-with-image": SplitWithImage,
  centered: Centered,
  "two-columns": TwoColumns,
  "two-columns-with-image": TwoColumnsWithImage,
  "two-columns-with-testimonial": TwoColumnsWithTestimonial,
  "simple-three-column": SimpleThreeColumn,
  "with-testimonial-and-stats": WithTestimonialAndStats,
  "three-column-image": ThreeColumnImage,
  stat: Stat,
  /* Marketing / Feedback / 404 Pages */
  "simple-404" : Simple404,
  /* Marketing / PageSections / CTA Sections */
  "brand-panel-overlapping-image": BrandPanelOverlappingImage,
  "brand-panel-app-screenshot": BrandPanelAppScreenshot,
  "simple-justified": SimpleJustified,
  "simple-centered-branded": SimpleCenteredBranded,
  /* Marketing / PageSections / FAQs */
  "offset-with-supporting-text": OffsetSupportingText,
  /* Marketing / PageSections / Contact Sections */
  "contact-centered": ContactCentered,
  /* Marketing / PageSections / Testimonials */
  "testimonial-simple-centered": TestimonialsSimpleCentered,
  /* TBB */
  "centered-accordion": CenteredAccordion,
  /* Custom */
  "video": Video,
  "google-map":GoogleMap
};

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return <Component blok={blok} key={blok._uid} />;
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null;
};

export default DynamicComponent;
