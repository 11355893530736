
import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import { LabelColour } from 'src/utils/ColourUtilities'

const LogoClouds = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white pt-16 pb-20 px-6 lg:pt-24 lg:pb-28">
                <div className="max-w-7xl mx-auto text-center">
                    <Label className={`${LabelColour(blok.colour)}`}>{blok.label}</Label>
                    <Heading size="h2" className="font-medium">{blok.title}</Heading>
                    <div className="mt-6 grid grid-cols-2 md:grid-cols-4">
                        {blok.logos.map((item) => (
                            <div className="col-span-1 flex justify-center sm:py-8 py-4 sm:px-8 px-4" key={item.id}>
                                <img src={item?.filename} loading="lazy" alt={item?.alt} className="block m-auto"/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default LogoClouds
  