import React from 'react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import RichText from 'src/utils/RichText'
import FormSubmitButton from './FormSubmitButton'

const Form = ({name, label, title, description, action, children }) => {
    return (
        <div className="relative">
            <Label className="text-orange">{label}</Label>
            <Heading size="h2" className="">{title}</Heading>
            <div className="mt-4 max-w-lg prose">
                <RichText>{description}</RichText>
            </div>
            <div className="mt-12">
                <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 max-w-4xl font-body" name={name} action={action} method="POST" netlify-honeypot="bot-field" data-netlify="true">
                    {children}
                    <FormSubmitButton form={name}/>
                </form>
            </div>
        </div>
    )
}

export default Form